import React, { useEffect, useState } from "react";
import { Form, Spin } from "antd";
import { useHistory } from "react-router";
import { useAxios } from "hooks";
import { Notifications, setLocalStorage } from "utils";
import { CORPORATE_PATHS } from "routing/Paths";

const LoginForm = (props) => {
  const [isLoading, setIsLoading] = useState(false);
  const [loginResponse, loginStatus, userLogin] = useAxios("post");

  const history = useHistory();



  useEffect(() => {
    if (loginResponse) {
      try {
        if (loginStatus) {
          setLocalStorage("userInfo", {
            loginToken: loginResponse.data.access,
            isLoggedIn: true,
            user_id: loginResponse.data.user_id,
          });
          history.push({
            pathname: CORPORATE_PATHS.dashboard,
          });
        } else {
          setIsLoading(false);
          setTimeout(() => {}, 1000);
          Notifications(
            "error",
            "Error",
            "Failed to login with given credentials"
          );
        }
      } catch (error) {
        Notifications(
          "error",
          "Error",
          "Failed to login with given credentials"
        );
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [loginStatus]);

  const onFinish = (values) => {
    setIsLoading(true);
    const loginUrl = "token/";
    const payload = JSON.stringify({
      username: values.username,
      password: values.password,
    });
    userLogin(loginUrl, payload);
  };

  const onFinishFailed = (errorInfo) => {};

  return (
    <>
      <Spin spinning={isLoading}>
        <Form
          onFinish={onFinish}
          onFinishFailed={onFinishFailed}
          autoComplete="off"
        >
          {/* <div className="text-center">
            <img
              src="./assets/img/logo.svg"
              alt=""
              className="img-fluid"
              width={80}
            />
          </div> */}

          <div className="mt-4">
            <label className="text-green fw-700 px-0">Username</label>
            <Form.Item
              name="username"
              rules={[
                {
                  required: true,
                  message: "Please input your username!",
                },
              ]}
            >
              <input
                className="px-0 text-green fw-500 form-control form-control-sm border-0 bg-transparent border-bottom-green-1 rounded-0"
                type="text"
              />
            </Form.Item>
          </div>

          <div className="mt-4">
            <label className="text-green fw-700 px-0">Password</label>
            <Form.Item
              name="password"
              rules={[
                {
                  required: true,
                  message: "Please input your pasword!",
                },
              ]}
            >
              <input
                className="px-0 text-green fw-500 form-control form-control-sm border-0 bg-transparent border-bottom-green-1 rounded-0"
                type="password"
              />
            </Form.Item>
          </div>

          <div className="mt-4">
            <input
              className="btn text-uppercase w-100 blue-gradient-bg-1 rounded-pill text-white fw-800 border-0 px-5"
              type="submit"
              value="login"
            />
          </div>
        </Form>
      </Spin>
    </>
  );
};

LoginForm.propTypes = {};

export default LoginForm;
