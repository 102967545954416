import NotFound from "components/NotFound";

import Login from "pages/Login";
import { BrowserRouter as Router, Switch, Route } from "react-router-dom";
import { CORPORATE_PATHS, ROOT_PATH } from "./Paths";
import PrivateRoute from "./PrivateRoute";
import BulkMessages from "pages/corporate/BulkMessages";
import SentMessages from "pages/corporate/SentMessages";
import Dashboard from "pages/corporate/Dashboard";
const Routes = () => (
  <>
    <Router>
      <Switch>
        <Route exact path={ROOT_PATH} component={Login} />
        <PrivateRoute
          exact
          path={CORPORATE_PATHS.dashboard}
          component={Dashboard}
        />

        <PrivateRoute
          exact
          path={CORPORATE_PATHS.bulkMessages}
          component={BulkMessages}
        />
        <PrivateRoute
          exact
          path={CORPORATE_PATHS.sentMessages}
          component={SentMessages}
        />
        <Route path="*">
          <NotFound />
        </Route>
      </Switch>
    </Router>
  </>
);

export default Routes;
