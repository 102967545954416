import React from "react";
import Messages from "components/bulk/Messages";
import Main from "./Main";

const BulkMessages = () => {
  return (
    <>
      <Main
        component={<Messages />}
        title={` BULK MESSAGES`}
      />
    </>
  );
};

export default BulkMessages;
