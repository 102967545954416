export const ROOT_PATH = '/'

function path(root, sublink) {
  return `${root}${sublink}`
}

export const CORPORATE_PATHS = {
  dashboard: path(ROOT_PATH, 'dashboard'),
  singlePurchase: path(ROOT_PATH, 'single-purchases'),
  bulkMessages: path(ROOT_PATH, 'bulk-messages'),
  sentMessages: path(ROOT_PATH, 'sent-messages'),
  
}
