import { useEffect, useRef, useState } from "react";
import { Spin, Form } from "antd";
import TemplateCSV from "assets/templates/bulk_whatsapp_template_wvyH1rS.csv";
import { Notifications } from "utils";
import axios from "axios";
import { Config } from "config";

const MessagesPackage = () => {
  const config = Config()
  const [csvFile, setCsvFile] = useState();
  const [imageFile, setImageFile] = useState();
  const [templates, setTemplates] = useState([]);
  const [isLoading, setIsLoading] = useState(false);
  const [selectedTemplateValue, setSelectedTemplateValue] = useState("");
  const [selectedTemplateId, setSelectedTemplateId] = useState("");

  const [form] = Form.useForm();

  const imageInputRef = useRef(null);
  const csvInputRef = useRef(null);

  useEffect(() => {
    getTemplates();
    getMessages();
  }, []);

  const getTemplates = () => {
    const url = `${config.base_url}templates/`;
    const headers = {
      "Content-Type": "multipart/form-data",
      apiUsername: 'Credspace',
      apiPassword: '@Bundex123!'
    };

    axios
      .get(url, {
        headers: headers,
        mode: "cors" 
      })
      .then((res) => {
        if (res.status === 200) {
          setTemplates(res.data);
        } else if (res.status === 401) {
          console.log('Error in fetching data');
        }
      })
      .catch((error) => {
        if (error.response && error.response.status === 401) {
          console.log(error);
        }
      });
  };

  const getMessages = () => {
    const url = `${config.base_url}sent_messages/`;
    const headers = {
      "Content-Type": "multipart/form-data",
      apiUsername: 'Credspace',
      apiPassword: '@Bundex123!'
    };

    axios
      .get(url, {
        headers: headers,
        mode: "cors" 
      })
      .then((res) => {
        if (res.status === 200) {
          console.log(templates);
        } else if (res.status === 401) {
          console.log('Error in fetching data');
        }
      })
      .catch((error) => {
        if (error.response && error.response.status === 401) {
          console.log(error);
        }
      });
  };

  const uploadMessages = (values) => {
    setIsLoading(true);
    const url = `${config.base_url}bwm/`;
    const formData = new FormData();
  
    formData.append('template', selectedTemplateId);  // Use selectedTemplateId here
    formData.append('message', values.message);
    if (imageFile) {
      formData.append('file', imageFile);
    }
    formData.append('csv', csvFile);
  
    const headers = {
      'Content-Type': 'multipart/form-data',
      'apiUsername': 'Credspace',
      'apiPassword': '@Bundex123!'
    };
  
    axios.post(url, formData, { headers: headers })
      .then((res) => {
        setIsLoading(false);
        if (res.status === 200) {
          Notifications('success', 'Success', 'Messages Sent');
        } else {
          Notifications('error', 'Error', 'Failed to send messages');
        }
      })
      .catch((error) => {
        setIsLoading(false);
        Notifications('error', 'Error', 'Failed to send messages');
        console.error(error);
      });
  };

  const handleTemplateChange = (e) => {
    const selectedTemplate = templates.find(template => template.value === e.target.value);
    setSelectedTemplateValue(e.target.value);
    setSelectedTemplateId(selectedTemplate ? selectedTemplate.id : "");
    // Clear file inputs
    setImageFile(null);
    setCsvFile(null);
    if (imageInputRef.current) {
      imageInputRef.current.value = "";
    }
    if (csvInputRef.current) {
      csvInputRef.current.value = "";
    }
  };

  return (
    <>
      <Spin
        tip="Please wait while we clean your file....."
        spinning={isLoading}
      >
        <Form name="basic" onFinish={uploadMessages} form={form} autoComplete="off">
          <Spin spinning={isLoading}>
            <div className="row">
              <div className="mt-4">
                <label className="text-green fw-700 px-0">
                  Select Message Format
                </label>
                <Form.Item
                  name="template"
                  rules={[
                    {
                      required: true,
                      message: "Please select a service provider!",
                    },
                  ]}
                >
                  <select
                    className="form-select px-0 text-green fw-500 form-control form-control-sm border-0 bg-transparent border-bottom-green-1 rounded-0 fs-12px"
                    aria-label="Default select example"
                    onChange={handleTemplateChange}
                  >
                    <option selected value="">
                      Select Message Format{" "}
                    </option>
                    {!!templates &&
                      templates.map((item) => (
                        <option key={item.id} value={item.value}>
                          {item.name}
                        </option>
                      ))}
                  </select>
                </Form.Item>
              </div>

              <div className="mt-4">
                <label className="text-green fw-700 px-0">
                  Message
                </label>
                <Form.Item
                  name="message"
                  rules={[
                    {
                      required: true,
                      message: "Please type your message!",
                    },
                  ]}
                >
                  <textarea
                    className="form-control form-control-sm border-0 bg-transparent border-bottom-green-1 rounded-0 fs-12px"
                    placeholder="Type your message here..."
                    rows={4}
                  ></textarea>
                </Form.Item>
              </div>

              {selectedTemplateValue === "image_message" && (
                <div className="col-12 col-md-12 mt-4">
                  <label className="text-green-2 fw-700 px-0">
                    Upload Image
                  </label>
                  <Form.Item name="image">
                    <input
                      className="px-0 text-green fw-500 form-control form-control-sm border-0 bg-transparent border-bottom-green-1 rounded-0"
                      type="file"
                      accept="image/*"
                      onChange={(e) => {
                        setImageFile(e.target.files[0]);
                      }}
                      ref={imageInputRef}
                    />
                  </Form.Item>
                </div>
              )}

              {selectedTemplateValue === "video_message" && (
                <div className="col-12 col-md-12 mt-4">
                  <label className="text-green-2 fw-700 px-0">
                    Upload Video
                  </label>
                  <Form.Item name="image">
                    <input
                      className="px-0 text-green fw-500 form-control form-control-sm border-0 bg-transparent border-bottom-green-1 rounded-0"
                      type="file"
                      accept="video/mp4"
                      onChange={(e) => {
                        setImageFile(e.target.files[0]);
                      }}
                      ref={imageInputRef}
                    />
                  </Form.Item>
                </div>
              )}

              {selectedTemplateValue === "document_message" && (
                <div className="col-12 col-md-12 mt-4">
                  <label className="text-green-2 fw-700 px-0">
                    Upload Document
                  </label>
                  <Form.Item name="image">
                    <input
                      className="px-0 text-green fw-500 form-control form-control-sm border-0 bg-transparent border-bottom-green-1 rounded-0"
                      type="file"
                      accept="application/pdf"
                      onChange={(e) => {
                        setImageFile(e.target.files[0]);
                      }}
                      ref={imageInputRef}
                    />
                  </Form.Item>
                </div>
              )}

              <div className="col-12 col-md-12 mt-4">
                <label className="text-green-2 fw-700 px-0">
                  Upload CSV File
                </label>
                <Form.Item name="file">
                  <input
                    className="px-0 text-green fw-500 form-control form-control-sm border-0 bg-transparent border-bottom-green-1 rounded-0"
                    type="file"
                    accept=".csv"
                    onChange={(e) => {
                      setCsvFile(e.target.files[0]);
                    }}
                    ref={csvInputRef}
                  />
                </Form.Item>
              </div>

              <div className="text-center mt-4">
                <input
                  className="btn text-uppercase blue-gradient-bg-4 rounded-0 text-white fw-800 border-0 px-5"
                  type="submit"
                  value="Submit"
                />

                <a
                  href={TemplateCSV}
                  style={{ marginLeft: "12px" }}
                  className="btn text-uppercase orange-gradient-bg-2 rounded-0 text-white fw-800 border-0 px-5"
                >
                  Download Template
                </a>
              </div>
            </div>
          </Spin>
        </Form>
      </Spin>
    </>
  );
};

export default MessagesPackage;
