import React, { useEffect, useState } from "react";
import Header from "components/Header";
import SideNav from "components/SideNav";
import { useAxios } from "hooks";
import { getLocalStorage } from "utils";
import MaterialTable from "material-table";
import { Tooltip } from "antd";
import { CSVLink } from "react-csv";
import { FaFileExport } from "react-icons/fa";
import axios from "axios";
import { Config } from "config";

const Dashboard = (props) => {
  const config = Config()
  const [isLoading, setIsLoading] = useState(true);
  const [messages, setMessages] = useState([]);
  const [messageStatistics, setMessagesStatistics] = useState([]);
  useEffect(() => {
    getMessages()
    getMessageStatistics()
  }, []);

  const headers = {
      "Content-Type": "multipart/form-data",
      apiUsername: 'Credspace',
      apiPassword: '@Bundex123!'
    };

  const getMessages = () => {
    const url = `${config.base_url}sent_messages/`;
    axios
      .get(url, {
        headers: headers,
        mode: "cors" 
      })
      .then((res) => {
        if (res.status === 200) {
          setMessages(res.data)
          console.log(messages)
          setIsLoading(false)
        } else if (res.status === 401) {
          console.log('error in fetching data');
        }
      })
      .catch((error) => {
        if (error.response && error.response.status === 401) {
          console.log(error);
        }
      });
  };
  const getMessageStatistics = () => {
    const url = `${config.base_url}message_statistics/`;
    axios
      .get(url, {
        headers: headers,
        mode: "cors" 
      })
      .then((res) => {
        if (res.status === 200) {
          setMessagesStatistics(res.data)
          console.log(messageStatistics)
          setIsLoading(false)
        } else if (res.status === 401) {
          console.log('error in fetching data');
        }
      })
      .catch((error) => {
        if (error.response && error.response.status === 401) {
          console.log(error);
        }
      });
  };
  const messagesData = messages.map((msg) => {
    const dateCreated = new Date(msg.created_at);
  
    const date = dateCreated.toLocaleDateString();
    const time = dateCreated.toLocaleTimeString();
  
    return {
      message: msg.message,
      dateCreated: date,
      phoneNumber: msg.phoneNumber,
      status: msg.status,
      time: time,
    };
  });
  
  const csvheaders = [
    { label: 'Date Created', key: 'dateCreated' },
    { label: 'Time', key: 'time' },
    { label: 'Phone Number', key: 'vendorReference' },
    { label: 'Type', key: 'csvType' },
    { label: 'Proccessed', key: 'noOfProccessed' },
    { label: 'Total', key: 'noOfTransactions' },
    { label: 'Amount', key: 'amount' },
    { label: 'Failed', key: 'noOfFailed' },
    { label: 'Phone Number', key: 'phone_number' },
    { label: 'Status', key: 'status' },
  ]
  return (
    <>
      <Header />

      <div className="container-fluid">
        <div className="row">
          <SideNav />

          <main className="col-md-9 ms-sm-auto col-lg-10 p-0">
            <div className="row px-4 pb-4 m-0">
                <div className="col-12 col-md-6 col-lg-3 mt-4">
                  <div className="card box-shadow-1 border-0 rounded-0">
                    <div className="card-body">
                      <h6 className="text-uppercase fw-800 fs-12px text-green-3">
                        Total Messages
                      </h6>

                      {/* eslint-disable-next-line jsx-a11y/heading-has-content */}
                      <h4 className="text-green fw-600 d-inline">
                        {messageStatistics.total}
                      </h4>
                      <span className="text-green ms-2 fw-600">Messages</span>

                      <a
                        href
                        className="btn btn-sm w-100 blue-gradient-bg-1 border-0 rounded-pill text-white fw-800 mt-2 fs-12px"
                      >
                        Total Messages
                      </a>
                    </div>
                  </div>
                </div>
                <div className="col-12 col-md-6 col-lg-3 mt-4">
                  <div className="card box-shadow-1 border-0 rounded-0">
                    <div className="card-body">
                      <h6 className="text-uppercase fw-800 fs-12px text-green-3">
                        Successful Messages
                      </h6>

                      {/* eslint-disable-next-line jsx-a11y/heading-has-content */}
                      <h4 className="text-green fw-600 d-inline">
                        {messageStatistics.successful}
                      </h4>
                      <span className="text-green ms-2 fw-600">Successful Messages</span>

                      <a
                        href
                        className="btn btn-sm w-100 blue-gradient-bg-1 border-0 rounded-pill text-white fw-800 mt-2 fs-12px"
                      >
                        Successful Messages
                      </a>
                    </div>
                  </div>
                </div>
                <div className="col-12 col-md-6 col-lg-3 mt-4">
                  <div className="card box-shadow-1 border-0 rounded-0">
                    <div className="card-body">
                      <h6 className="text-uppercase fw-800 fs-12px text-green-3">
                        Failed Messages
                      </h6>

                      {/* eslint-disable-next-line jsx-a11y/heading-has-content */}
                      <h4 className="text-green fw-600 d-inline">
                        {messageStatistics.failed}
                      </h4>
                      <span className="text-green ms-2 fw-600"> Messages</span>

                      <a
                        href
                        className="btn btn-sm w-100 blue-gradient-bg-1 border-0 rounded-pill text-white fw-800 mt-2 fs-12px"
                      >
                        Failed Messages
                      </a>
                    </div>
                  </div>
                </div>
              </div>
            <div className="row p-4 m-0">
              <div className="card border-0 p-0 rounded-0 box-shadow-1">
                <div className="card-header border-0 blue-gradient-bg-2 text-center">
                  <h4 className="text-white fw-700"> Recent Messages</h4>
                </div>
                <div className="card-body px-0 pt-2 pb-4">
                  <div className="table-responsive">
                    <MaterialTable
                      columns={[
                        { title: "Date", field: "dateCreated" },
                        { title: "Time", field: "time" },
                        {
                          title: "Message",
                          field: "message",
                        },
                        { title: "Phone Number", field: "phoneNumber" },
                        { title: "Status", field: "status" },
                        
                      ]}
                      isLoading={isLoading}
                     
                      data={messagesData}
                    />
                  </div>
                </div>
              </div>
            </div>
          </main>
        </div>
      </div>
    </>
  );
};

export default Dashboard;
