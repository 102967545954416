import logo from "assets/img/logo-sm.svg";
import barsIcon from "assets/icons/bars.svg";
import { useState, useEffect } from "react";
import { useAxios } from "hooks";
import { getLocalStorage } from "utils";

const Header = (props) => {
  const [userInfo, setUserInfo] = useState({});
  const [response, status, httpRequest] = useAxios("get");
  const headers = {
    headers: {
      Authorization: `Bearer ${getLocalStorage("userInfo").loginToken}`,
      "Content-Type": "application/json",
    },
    mode: "cors",
  };

  useEffect(() => {
    if (response) {
      if (status) {
        setUserInfo(response.data.message);
      }
    }

    getUserInfo();
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [status]);

  const getUserInfo = () => {
    const url = "user/info/";
    httpRequest(url, headers);
  };

  return (
    <header className="flex-md-nowrap p-0 navbar navbar-dark blue-gradient-bg-2 box-shadow-1">
      <nav className="navbar navbar-dark blue-gradient-bg-2 fixed-top box-shadow-1">
        <div className="container-fluid d-flex justify-content-start align-items-center">
          <button
            className="navbar-toggler"
            type="button"
            data-bs-toggle="collapse"
            data-bs-target="#sidebarMenu"
            aria-controls="navbarSupportedContent"
            aria-expanded="false"
            aria-label="Toggle navigation"
          >
            
          </button>

          

          <li className="nav-item dropdown list-unstyled ms-auto">
            <h5 className="nav-link text-white text-capitalize" href="#">
              BWM
              {/* <span className="text-lowercase">`s </span>
              balance: ${userInfo.balance} */}
            </h5>
          </li>
        </div>
      </nav>
    </header>
  );
};

Header.propTypes = {};

export default Header;
